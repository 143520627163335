import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import Resource from '../global/Resource'

interface Props {
	standard?: 'CCSS' | 'TEKS'
}

export default function ReviewBadges2({ standard = 'CCSS' }: Props) {
	const { edReports, essa, ies, plpg, results, trr, knowledge } = useStaticQuery<IQuery>(query)

	return (
		<div>
			<div className="flex flex-wrap items-center">
				{standard === 'TEKS' && (
					<div>
						<a href="https://texasresourcereview.org/publishers/american-reading-company" target="_blank" rel="noopener noreferrer">
							<GatsbyImage image={trr.gatsbyImageData} alt="texas resource review badge" />
						</a>
					</div>
				)}
				<div className="w-1/6 md:w-1/3">
					<div className="p-1 md:p-4">
						<a href="https://www.edreports.org/reports/overview/arc-american-reading-company-core-2017" target="_blank" rel="noopener noreferrer">
							<div className="flex justify-center">
								<GatsbyImage image={edReports.gatsbyImageData} alt="ed reports badge" className="max-w-[135px]" />
							</div>
						</a>
					</div>
				</div>
				<div className="w-1/6 md:w-1/3">
					<div className="p-1 md:p-4">
						<a href="https://plpartnerguide.org/partner/american-reading-company/" target="_blank" rel="noopener noreferrer">
							<div className="flex justify-center">
								<GatsbyImage image={plpg.gatsbyImageData} alt="plpg badge" className="max-w-[135px]" />
							</div>
						</a>
					</div>
				</div>
				<div className="w-1/6 md:w-1/3">
					<div className="p-1 md:p-4">
						<div className="flex justify-center">
							<Resource title="IES Study" linkOnly noZoom>
								<GatsbyImage image={ies.gatsbyImageData} alt="ies study badge" className="max-w-[135px]" />
							</Resource>
						</div>
					</div>
				</div>
				<div className="w-1/6 md:w-1/3">
					<div className="p-1 md:p-4">
						<div className="flex justify-center">
							<a href="https://www.evidenceforessa.org/program/arc-core-formerly-zoology-one/" target="_blank" rel="noopener noreferrer">
								<GatsbyImage image={essa.gatsbyImageData} alt="essa badge" className="max-w-[150px]" />
							</a>
						</div>
					</div>
				</div>
				<div className="w-1/6 md:w-1/3">
					<div className="p-1 md:p-4">
						<div className="flex justify-center">
							<Resource title="Results Packet" linkOnly noZoom>
								<GatsbyImage image={results.gatsbyImageData} alt="results packet badge" className="max-w-[135px]" />
							</Resource>
						</div>
					</div>
				</div>
				<div className="w-1/6 md:w-1/3">
					<div className="p-1 md:p-4">
						<div className="flex justify-center">
							<a href="https://knowledgematterscampaign.org/curriculum/arc-core/" target="_blank" rel="noopener noreferrer">
								<GatsbyImage image={knowledge.gatsbyImageData} alt="knowlege matters badge" className="max-w-[135px]" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

interface IQuery {
	trr: { gatsbyImageData: IGatsbyImageData }
	essa: { gatsbyImageData: IGatsbyImageData }
	edReports: { gatsbyImageData: IGatsbyImageData }
	plpg: { gatsbyImageData: IGatsbyImageData }
	knowledge: { gatsbyImageData: IGatsbyImageData }
	ies: { gatsbyImageData: IGatsbyImageData }
	results: { gatsbyImageData: IGatsbyImageData }
}

const query = graphql`
	query {
		trr: contentfulAsset(title: { eq: "Texas Resource Review Badge" }) {
			gatsbyImageData(height: 210)
		}
		essa: contentfulAsset(title: { eq: "ESSA Badge" }) {
			gatsbyImageData(height: 210)
		}
		edReports: contentfulAsset(title: { eq: "EdReports Badge" }) {
			gatsbyImageData(height: 210)
		}
		plpg: contentfulAsset(title: { eq: "PLPG Badge" }) {
			gatsbyImageData(height: 210)
		}
		ies: contentfulAsset(title: { eq: "IES Study Badge" }) {
			gatsbyImageData(height: 210)
		}
		results: contentfulAsset(title: { eq: "ARC Core Results Badge" }) {
			gatsbyImageData(height: 210)
		}
		knowledge: contentfulAsset(title: { eq: "Knowledge Matters Badge" }) {
			gatsbyImageData(height: 210)
		}
	}
`
