import { Button } from '@arckit/ui'
import { Section, Row, Col } from 'components/grid'
import { H2 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function JoinOurWebinars() {
	return (
		<Section flatGray>
			<Row middle className="py-20">
				<Col width="w-full sm:w-7/12" className="text-center sm:text-left">
					<div className="mx-auto mb-2 h-3 w-40 bg-ab-100 sm:mx-0"></div>
					<H2 className="mb-4">Join the Conversation</H2>
					<P className="mb-6 lg:mb-8">
						Discover unparalleled insights and engage with literacy pioneers and thought leaders by participating in our ongoing webinar series and in-person events.
					</P>
					{/* <div className="rounded-lg shadow border p-6 max-w-max mb-8 relative overflow-hidden">
						<div className="absolute top-4 -left-9 bg-ar-100 py-1 -rotate-45 w-32">
							<span className="text-white text-xs font-semibold uppercase block text-center">featured</span>
						</div>
						<div className="flex gap-3 mb-2">
							<div className="border-r pr-3 max-w-max text-center flex justify-center items-center pt-4">
								<div>
									<span className="uppercase block text-sm font-light">WED</span>
									<span className="block font-light text-lg">23</span>
								</div>
							</div>
							<div>
								<span className="block font-bold">Literacy Leadership Summit: Designing to the Margins</span>

								<div className="flex gap-2 items-center justify-between">
									<div>
										<span className="font-light text-sm block">Wednesday, October 23 · 9am - 3pm CDT</span>
										<span className="font-light text-sm block text-gray-500">
											Chicago Marriott Oak Brook <br /> 1401 West 22nd Street Oak Brook, IL 60523
										</span>
										<a
											href="https://www.eventbrite.com/e/literacy-leadership-summit-designing-to-the-margins-tickets-996514901897?aff=oddtdtcreator"
											target="_blank"
											className="underline"
										>
											<button className="block font-medium text-sm bg-ab-100 text-white rounded px-2 py-[2px] mt-[2px] mb-1">
												Register to join us in person!
											</button>
										</a>
									</div>
								</div>
							</div>
							<div>
								<img src="/images/home/map.png" alt="map" className="h-28 rounded-lg" />
							</div>
						</div>
					</div> */}
					<div className="flex flex-wrap items-center justify-center gap-6 gap-y-4 sm:justify-start">
						<a href="https://info.americanreading.com/upcoming-webinar" className="hover:no-underline" target="_blank">
							<Button size="xl">Explore Upcoming Webinars</Button>
						</a>
						<a href="https://info.americanreading.com/email-signup-recordings" target="_blank" className="text-gray-500 hover:text-ab-100 hover:no-underline">
							<div className="flex gap-2">
								<span className="text-gray-500">Revisit Previous Webinars</span>
								<div className="flex h-6 w-6 items-center justify-center rounded-full border border-ab-100">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 w-3 text-ab-100">
										<path
											fillRule="evenodd"
											d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</a>
					</div>
				</Col>
				<Col width="w-5/12" className="hidden sm:block">
					<StaticImage src="../../../static/images/home/webinar.png" alt="webinar" />
				</Col>
			</Row>
		</Section>
	)
}
