import { H2 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import React, { ReactNode, useState } from 'react'
import { Collapse } from 'react-collapse'
import { twMerge } from 'tailwind-merge'

interface Props {
	heading?: string
	items: IQandA[]
}

export default function FAQ({ heading = 'Questions You Might Want Answered First', items }: Props) {
	return (
		<div className="relative overflow-hidden rounded-lg bg-gray-100 p-8 lg:p-20">
			<div className="absolute bottom-auto left-auto right-0 top-0 -z-[1] h-[300px] w-[300px] rounded-full bg-ab-100/50 opacity-40 blur-[80px]" />
			<div className="absolute top-auto right-auto left-0 -bottom-1/4 -z-[1] h-[300px] w-[300px] rounded-full bg-purple-500/50 opacity-40 blur-[80px]" />
			<div className="mx-auto mb-2 h-3 w-40 bg-ab-100 sm:mx-0 lg:w-56"></div>
			<H2 className="mb-4 text-center sm:text-left">{heading}</H2>
			<div>
				{items.map((item) => (
					<QuestionAndAnswer key={`question-answer-${item.id}`} item={item} />
				))}
			</div>
		</div>
	)
}

export interface IQandA {
	id: number | string
	question: string | ReactNode[]
	answer: string | ReactNode[]
}

interface QandAProps {
	item: IQandA
}

function QuestionAndAnswer({ item }: QandAProps) {
	const [open, setOpen] = useState(false)

	const { question, answer } = item

	return (
		<div className="mb-4 rounded-lg bg-white p-6 last:mb-0">
			<button onClick={() => setOpen(!open)} className="w-full text-left">
				<div className="flex items-center justify-between gap-3">
					<span className="font-semibold tracking-wide">{question}</span>
					{answer && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className={twMerge('h-5 w-5 flex-none transition-all duration-200 hover:rotate-180', open && 'rotate-180')}
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
						</svg>
					)}
				</div>
			</button>
			<Collapse
				isOpened={open}
				theme={{
					collapse: 'ReactCollapse--collapse transition-all duration-200 ease-in-out',
					content: 'ReactCollapse--content'
				}}
			>
				<div className="mt-2">
					<P size="sm">{answer}</P>
				</div>
			</Collapse>
		</div>
	)
}
