import { Button } from '@arckit/ui'
import { Input } from 'components/forms/Inputs'
import { H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface Props {
	pageName: string
	subHeading?: string
	heading?: string
	underline?: string
	copy?: string
}

export default function GetStartedCTA({
	pageName,
	subHeading = "Let's Connect",
	heading = 'Your All-In-One ELA Curriculum, K-12',
	underline = 'All-In-One',
	copy = 'Core, Intervention, Supplemental & more. All in one package.'
}: Props) {
	const methods = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })
	const [email, setEmail] = useState('')

	function submitForm() {
		navigate('/connect', { state: { email, pageName } })
	}

	const constructHeading = (heading: string, underline?: string) => {
		if (!underline) return <H3 className="mb-3 lg:leading-normal">{heading}</H3>
		const underlineIndex = heading.indexOf(underline)
		if (underlineIndex === -1) {
			// If underline text is not found, return the heading as is
			return <H3 className="mb-3 lg:leading-normal">{heading}</H3>
		} else {
			// If found, split the heading into two parts
			const heading1 = heading.substring(0, underlineIndex)
			const heading2 = heading.substring(underlineIndex + underline.length)
			return (
				<H3 className="mb-3 lg:leading-normal">
					{heading1}{' '}
					<span className="relative leading-relaxed after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-y sm:leading-normal">
						{underline}
					</span>{' '}
					{heading2}
				</H3>
			)
		}
	}

	return (
		<div className="relative rounded-lg border bg-white p-8 shadow-lg">
			<div className="absolute top-[5.5rem] -right-10 hidden sm:block">
				<svg width="34" height="134" viewBox="0 0 34 134" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="31.9993" cy="132" r="1.66667" transform="rotate(180 31.9993 132)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="88" r="1.66667" transform="rotate(180 31.9993 88)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="45" r="1.66667" transform="rotate(180 31.9993 45)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="16" r="1.66667" transform="rotate(180 31.9993 16)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="59" r="1.66667" transform="rotate(180 31.9993 59)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#d1d5db"></circle>
					<circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="132" r="1.66667" transform="rotate(180 17.3333 132)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="88" r="1.66667" transform="rotate(180 17.3333 88)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="45" r="1.66667" transform="rotate(180 17.3333 45)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="16" r="1.66667" transform="rotate(180 17.3333 16)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="59" r="1.66667" transform="rotate(180 17.3333 59)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#d1d5db"></circle>
					<circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="132" r="1.66667" transform="rotate(180 2.66536 132)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="88" r="1.66667" transform="rotate(180 2.66536 88)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="45" r="1.66667" transform="rotate(180 2.66536 45)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="16" r="1.66667" transform="rotate(180 2.66536 16)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="59" r="1.66667" transform="rotate(180 2.66536 59)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#d1d5db"></circle>
					<circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#d1d5db"></circle>
				</svg>
			</div>

			<span className="mb-2 block text-base font-bold uppercase text-ab-100">{subHeading}</span>
			{constructHeading(heading, underline)}
			<P>{copy}</P>
			<div className="mt-6">
				<FormProvider {...methods}>
					<form id="email-capture" noValidate onSubmit={methods.handleSubmit(submitForm)}>
						<Input
							validation={methods.register({
								required: 'Email is required',
								pattern: {
									value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Please enter a valid email address'
								}
							})}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							id="get-started-cta-email"
							type="email"
							placeholder="your.email@company.com"
						/>
					</form>
				</FormProvider>
			</div>
			<div className="mt-6 flex flex-wrap items-center gap-2">
				<Button type="submit" form="email-capture" size="lg">
					Get Started
				</Button>

				<div className="flex flex-col gap-y-1">
					<ul className="list-disc pl-6 text-gray-400">
						<li>
							<span className="-ml-1 text-xs font-medium uppercase">No commitment</span>
						</li>
						<li>
							<span className="-ml-1 text-xs font-medium uppercase">Access Digital Samples</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
